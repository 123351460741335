import { GiBugNet } from '@react-icons/all-files/gi/GiBugNet'
import { HiHome } from '@react-icons/all-files/hi/HiHome'
import ReusableGrid from 'components/containers/ReusableGrid'
import BugReportModal from 'components/organisms/BugReportModal'
import Link from 'next/link'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { submitBugReportToDB } from 'services/client'
import { formatDateTime } from 'utils/DateTime'

// eslint-disable-next-line
import ClumsySVG from '/public/assets/opendoodles/clumsy.svg'

const NotFound = () => {
  const user = useSelector(state => state?.pr?.user?.info)
  const accessToken = useSelector(state => state?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  let dynamicHome

  if (accessToken) {
    dynamicHome = '/u/dashboard'
  } else {
    dynamicHome = '/'
  }

  const showBugReportModal = () => {
    const element = document.getElementById('bug-report-modal')
    element.showModal()
  }
  const closeBugReportModal = () => {
    const element = document.getElementById('bug-report-modal')
    element.close()
  }

  const SubmitBugReport = async formdata => {
    let payload = {
      message: formdata?.message,
      email: user?.email || formdata?.email,
      phone: user?.phone || formdata?.phone || '', // optional
      userId: user?.id || 'n/a',
      date: formatDateTime(new Date()),
    }

    try {
      const isBugReportSubmitted = await submitBugReportToDB(payload)

      if (isBugReportSubmitted) {
        closeBugReportModal()
        reset()
      }
    } catch (error) {
      toast.error(
        `Something went wrong!, Try emailing us at ${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`
      )
      console.error('Error in SubmitBugReport', error)
    }
  }

  return (
    <ReusableGrid>
      <div className='col-span-full flex flex-col items-center justify-center space-y-6'>
        <h1 className='text-4xl font-bold lg:text-6xl '>Oops! Its a 404</h1>

        <ClumsySVG className='w-72 md:w-80 lg:w-96' />

        <h2 className='text-xl font-bold lg:text-2xl '>We could not find that page for you.</h2>

        <button className='btn btn-outline btn-md btn-wide' onClick={showBugReportModal}>
          <GiBugNet size={24} />
          Report a bug
        </button>

        <BugReportModal
          handleSubmit={handleSubmit(SubmitBugReport)}
          user={user}
          errors={errors}
          register={register}
        />

        <Link href={dynamicHome} className='btn btn-outline btn-md btn-wide '>
          <HiHome size={24} />
          Return Home
        </Link>
      </div>
    </ReusableGrid>
  )
}

export default NotFound
